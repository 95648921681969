<template>
  <el-dialog title="申请为模版" :visible.sync="tobeTemFlag" width="40%" @closed="handleClose" @open="handleOpen">
    <div class="temBox">
      <div class="temImg">
        <div style="line-height: 40px">模版封面</div>
        <ReadyUploadSource
          :showStyle="{
            width: '200px',
            height: '356px',
          }"
          :path="temConfig.images"
          @getSource="getImg"
          @removeThis="removeImg"
        ></ReadyUploadSource>
        <p v-if="!temConfig.images" style="color: red; margin: 5px">请上传模版封面</p>
      </div>
      <el-form :model="temConfig" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="模版名称" prop="name">
          <el-input v-model="temConfig.name"></el-input>
        </el-form-item>
        <el-form-item label="模版分类" prop="industry_id">
          <el-select v-model="temConfig.industry_id" placeholder="请选择">
            <el-option v-for="(item, index) in styleArr" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模版详情" prop="details">
          <el-input v-model="temConfig.details"></el-input>
        </el-form-item>
        <el-form-item label="模版价格" prop="money">
          <el-input type="Number" :min="0" v-model="temConfig.money"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('closeTem')">取 消</el-button>
      <el-button type="primary" @click="uploadTem">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  props: {
    tobeTemFlag: {
      type: Boolean,
      default: false,
    },
    chooseThat: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ReadyUploadSource,
  },
  data() {
    return {
      rules: {
        industry_id: [{ required: true, message: '请选择模版分类', trigger: 'change' }],
        name: [{ required: true, message: '请填写模版名称', trigger: 'blur' }],
        details: [{ required: true, message: '请填写模版详情', trigger: 'blur' }],
        money: [{ required: true, message: '请填写模版价格', trigger: 'blur' }],
      },
      temConfig: {
        name: '',
        images: '',
        industry_id: '',
        details: '',
        money: '',
      },
      styleArr: [],
    };
  },
  methods: {
    // 获取图片
    getImg(imgUrl) {
      this.temConfig.images = imgUrl.path;
    },
    removeImg() {
      this.temConfig.images = '';
    },
    // 获取模版分类列表
    getStyleArr() {
      let that = this;
      this.$axios.post(this.$api.shopDiy.templatelist).then(res => {
        if (res.code == 0) {
          that.styleArr = res.result;
        }
      });
    },
    handleClose() {
      this.temConfig = {
        name: '',
        images: '',
        industry_id: '',
        details: '',
        money: '',
      };
      this.$emit('closeTem');
    },
    handleOpen() {
      this.getStyleArr();
    },
    uploadTem() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (!this.temConfig.images) {
            this.$message.warning('请上传模版封面');
            return;
          }
          this.temConfig.drafts_id = this.chooseThat.id;
          this.$axios.post(this.$api.shopDiy.releaseTemplate, this.temConfig).then(res => {
            if (res.code === 0) {
              this.$message.success(`申请成功，请等待审核`);
              this.$emit('success');
              this.$emit('closeTem');
            } else {
              this.$message.error(`${res.msg}`);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.temBox {
  display: flex;
}
</style>
